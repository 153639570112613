﻿declare global {
  interface Array<T> {
    cloneInstance(): Array<T>;
    sortNumberAscending(): Array<T>;
  }
}

Array.prototype.cloneInstance = function <T>() {
  let objClone: Array<T> = [];
  $.extend(true, objClone, this);
  return objClone;
};

Array.prototype.sortNumberAscending = function (): Array<number> {
  return this.sort((a: number, b: number) => a - b);
};

export {};
